$(document).foundation();

console.info(
    '%ccoded by DOCONO%c 🤘🏻 https://docono.io\n',
    'padding: 5px; background: #ea4e66; color: #ffffff; font-size: 13px;',
    ''
);

var $siteLogo = $('#site-logo');
var $scrollEl = document.scrollingElement || document.documentElement;

scrollSpy();
window.onscroll = function() { scrollSpy() };

$(function() {

    var $catalogue = $('.catalogue');
    var $menuToggler = $('#menu-toggler');

    if ($catalogue !== undefined) {
        var catalogue = new Catalogue($catalogue);
    }

    $('.hero_title_arrow').on('click', function (e) {
        let $el = $(this);
        $('html, body').animate({
            scrollTop: $el.offset().top + 50
        }, 500);
    })

    // scroll to if hash link
    if(window.location.hash.length > 0) {
        if(window.location.hash.substr(1, 7) !== 'filter_')
            scrollToHash(window.location.hash)
    }

    // on hash change (for history)
    $(window).on('hashchange', function() {
        scrollToHash(window.location.hash);
    });

    // catch all hash links
    $('a[href*="#"]').on('click', function(e) {

        // only catch hash from the same page
        if(this.pathname != window.location.pathname)
            return;

        if($('body').hasClass('navigation-active'))
            $menuToggler.trigger('click');

        e.preventDefault();
        scrollToHash(this.hash);
    });

});

function scrollSpy() {
    var topPos = $scrollEl.scrollTop;

    topPos == 0? $siteLogo.addClass('-on-top'): $siteLogo.removeClass('-on-top');
}

function scrollToHash(hash) {

    var $el = $(hash);

    if($el.length <= 0)
        return;

    history.pushState(null, null, hash);

    $('html, body').animate({
        scrollTop: $el.offset().top
    }, 500);
}
