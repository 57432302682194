class Catalogue {

    /**
     * constructor
     */
    constructor($catalogue) {
        this.$catalogue = $catalogue;

        this.$totalResults = this.$catalogue.find('.articles__counter .total-results');

        this.$reset = this.$catalogue.find('button.filter__reset');

        this.$recipes = this.$catalogue.find('article.article-teaser');
        this.recipes = [];

        this.$filter = this.$catalogue.find('.filter');
        this.$filters = this.$filter.find('input');
        this.filters = ['concept', 'characteristics', 'breadshape'];
        this.filterRelations = {
            concept: [],
            characteristics: [],
            breadshape: []
        }

        this.activeFilters = [];

        this.initRecipes();

        // get has
        let hash = window.location.hash;
        // check if hash is given and it is a filter hash
        if((hash.length > 0) && (hash.substr(1, 7) === 'filter_')) {
            let separatorPos = hash.indexOf('-', 8);
            // get filter name
            let filterName = hash.substr(8, separatorPos - 8);
            // get filter value
            let filterValue = hash.substr(separatorPos + 1);
            // set active filters
            this.activeFilters[filterName] = [filterValue];
            // check checkbox
            this.$filters.filter('#' + filterValue).prop('checked', true);
            // apply filter
            this.applyFilter();
        }

        this.registerListeners();
    }

    /**
     * init recipes
     * get all recipes in the catalog and assign them to the given filters
     */
    initRecipes() {

        $.each(this.$recipes, function (i, el) {
            let $el = $(el);

            let recipe = {
                id: $el.data('id'),
                $el: $el,
                filters: {
                    'concept': $el.data('filter-concept').split(';'),
                    'characteristics': $el.data('filter-characteristics').split(';'),
                    'breadshape': $el.data('filter-breadshape').split(';')
                }
            };

            this.recipes.push(recipe);
        }.bind(this));
    }

    /**
     * register catalogue listeners
     */
    registerListeners() {
        this.$reset.on('click', function (e) {
            this.resetFilters();
        }.bind(this))

        this.$filters.on('change', function (e) {
            this.toggleFilter(e.target)
        }.bind(this))
    }

    /**
     * add/remove selected filter option
     * @param input
     */
    toggleFilter(input) {
        let filterName = input.name.split('_');

        //
        if(this.activeFilters[filterName[0]] === undefined)
            this.activeFilters[filterName[0]] = [];

        // check if already active
        let index = this.activeFilters[filterName[0]].indexOf(filterName[1]);

        // check if is already active (toggle off)
        if(index > -1) {
            // remove from active filter list
            this.activeFilters[filterName[0]].splice(index, 1);

            // remove filter section if empty
            if (this.activeFilters[filterName[0]].length <= 0)
                delete this.activeFilters[filterName[0]];
        } else {
            // add to active filter list
            this.activeFilters[filterName[0]].push(filterName[1]);
        }

        // apply filters
        this.applyFilter();
    }

    /**
     * reset whole filter and filter form
     */
    resetFilters() {
        // reset active filters
        this.activeFilters = [];

        // uncheck all filters
        $.each(this.$filters, function (i, el) {
            $(el).prop('checked',false);
        });

        this.applyFilter();
        this.filterBuns();
    }

    /**
     * apply set filter rules to catalogue
     */
    applyFilter() {
        this.$recipes.removeClass('active');

        // enable or disable filter
        if (Object.keys(this.activeFilters).length > 0) {
            this.$catalogue.addClass('filter-active');
        } else {
            // remove filter active class
            this.$catalogue.removeClass('filter-active');
            // update result count
            this.$totalResults.html(this.$recipes.length);
            // skip filtering
            return;
        }

        let activeRecipes = this.recipes;
        let appliedFilters = 0;

        // run through all active filters
        for (let filterName in this.activeFilters) {
            let filterData = this.activeFilters[filterName];

            if(filterName === 'breadshape') {
                activeRecipes = activeRecipes.filter(function (recipe) {
                    return recipe.filters[filterName].some(r => filterData.indexOf(r) >= 0)
                });
            } else {
                activeRecipes = activeRecipes.filter(function (recipe) {
                    return filterData.every(f => recipe.filters[filterName].indexOf(f) >= 0)
                })
            }

            appliedFilters++;
        }

        // update result count
        this.$totalResults.html(activeRecipes.length);

        // show active recipes
        $.each(activeRecipes, function (i, recipe) {
            recipe.$el.addClass('active');
        })
    }
}
