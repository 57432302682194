var radarOptions = {
    elements: {
        point: {
            radius: 0 // hide value point (dataset)
        },
    },
    scale: {
        ticks: {
            display: false, // hide labes 1-4
            min: 0, // start value
            max: 4, // end value
            stepSize: 1 // steps between
        },
        pointLabels: {
            fontSize: 18 // label (softness etc.) font size
        },
        angleLines: {
            color: 'rgba(0, 0, 0, 1)' // label line color
        },
        gridLines: {
            circular: true, // making the scale lines round
            borderDash: [1, 5], // scale line dotted
            color: '#000000' // line colour
        }
    },
    tooltips: {
        enabled: false
    },
    legend: {
        display: false
    }
};